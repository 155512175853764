/**
 * Created by TapFun on 2022/03/04.
 */
import { reqGetPermissionButton } from '../api/system-api'

export default {
    getPermissionButton(permissionId, permissionButton) {
        reqGetPermissionButton({ permissionId: permissionId }).then((res) => {
            let buttonArray = res.data.data;
            buttonArray.forEach(function(obj) {
                if (obj.permissionUrl === 'readOnly') {
                    permissionButton.readOnly = true;
                }
                if (obj.permissionUrl === 'add') {
                    permissionButton.add = true;
                }
                if (obj.permissionUrl === 'edit') {
                    permissionButton.edit = true;
                }
                if (obj.permissionUrl === 'del') {
                    permissionButton.del = true;
                }
                if (obj.permissionUrl === 'batchDel') {
                    permissionButton.batchDel = true;
                }
                if (obj.permissionUrl === 'view') {
                    permissionButton.view = true;
                }
                if (obj.permissionUrl === 'disable') {
                    permissionButton.disable = true;
                }
                if (obj.permissionUrl === 'enable') {
                    permissionButton.enable = true;
                }
                if (obj.permissionUrl === 'import') {
                    permissionButton.import = true;
                }
                if (obj.permissionUrl === 'export') {
                    permissionButton.export = true;
                }
                if (obj.permissionUrl === 'print') {
                    permissionButton.print = true;
                }
            });
        })
    }
}
